import { getConfig } from '@/config';
import { useRoute, useRouter } from 'vue-router';
import * as Sentry from '@sentry/vue';
import type { State as ShopState } from '../../../stores/shop';
import useShopStore from '@/stores/shop';
import { backToDashboard } from '@/utils/backToDashboard';
import { isTokenExpired } from '@/utils/isTokenExpired';
import usePageBackupStore from '@/stores/pageBackup';
import useEditorStore from '@/modules/editor/modules/common/stores/editor';

export type ShopLoginResponse = {
  code: number;
  message: string;
  shopID: string;
  details: string | null;
  shopifyDomain?: string;
  userHash?: string;
  token: string;
  status: 'CONNECTED' | 'NOT_CONNECTED';
  firstName?: string;
  isGemPagesV7?: string;
  plan?: string;
  shopifyPlan?: string;
};

export const fetchShopLogin = async (
  connectType: ShopState['connectedType'],
  retries = 5,
): Promise<ShopLoginResponse | undefined> => {
  const editorStore = useEditorStore();
  const pageBackupStore = usePageBackupStore();
  const shopStore = useShopStore();
  const transaction = Sentry?.getCurrentHub()?.getScope()?.getTransaction();
  const headers: Record<string, any> = {
    'sentry-trace': `${transaction?.traceId}-${transaction?.spanId}`,
  };

  const gemSession = getGemSession();
  if (!gemSession) {
    if (!editorStore.getInitLoaded) {
      backToDashboard({
        delayRedirect: 8000,
      });
    } else {
      pageBackupStore.setReason('SESSION_EXPIRED');
    }
    return;
  }
  if (isTokenExpired(gemSession) && !shopStore.getIsAdminLogin) {
    if (!editorStore.getInitLoaded) {
      backToDashboard({
        delayRedirect: 8000,
      });
    } else {
      pageBackupStore.setReason('SESSION_EXPIRED');
    }
    return;
  }

  headers['X-Gem-Session'] = gemSession;
  headers['Content-Type'] = 'application/json';
  const urlPath = connectType == 'gempagesv7' ? 'shop-login/gempagesv7' : 'shop-login/gempages';
  const res = await fetch(`${getConfig('url').gateway}${urlPath}`, {
    headers,
  });

  // System error
  if (res.status >= 500) {
    if (retries > 0) {
      await sleep(500 * (5 - retries + 1));
      return fetchShopLogin(connectType, retries - 1);
    }
    throw new Error(res.statusText);
  }

  const resJson = (await res.json()) as ShopLoginResponse;

  // GemPages api me error
  if (resJson.code == 500100) {
    if (retries > 0) {
      await sleep(500 * (5 - retries + 1));
      return fetchShopLogin(connectType, retries - 1);
    }
  }

  // Can't permission access
  if (res.status == 401 || resJson.code == 401101 || resJson.code == 400120 || resJson.code == 400126) {
    backToDashboard({
      delayRedirect: 8000,
    });
    return;
  }

  if (connectType === 'gempagesv7') shopStore.setShopIdGempagesV7(resJson?.shopID);
  return resJson;
};

/**
 * Retrieves the gem session from the route query parameters or the shop store.
 *
 * @return {string} The gem session token.
 */
export const getGemSession = () => {
  const route = useRoute();
  const router = useRouter();
  const shopStore = useShopStore();

  const shopDomain = computed(() => shopStore.getShopDomain);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const paramShop = urlSearchParams.get('shop');

  const shopifyDomain = shopDomain.value || route?.query?.shop || paramShop;
  const queryToken = route?.query?.token?.toString();

  if (queryToken) {
    localStorage.setItem(`__gemSession_${shopifyDomain}`, queryToken);
    const query = {
      ...route.query,
      token: undefined,
    };
    router.replace({ query });
    return queryToken;
  }

  return localStorage.getItem(`__gemSession_${shopifyDomain}`) || '';
};

const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
