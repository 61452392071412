import useShopStore from '@/stores/shop';
import type { ConnectedType } from '@/types/connect';
import { useRoute } from 'vue-router';
import useNotification from './useNotification';
import { useShopQuery } from './useShopQuery';
import { emitUpdateShopPlan } from '@/modules/editor/modules/preview/utils/emitToIframe';
import { backToDashboard } from '@/utils/backToDashboard';
import useShopLoginQuery from '@/api/auth/queries/useShopLoginQuery';
import type { ShopLoginResponse } from '@/api/auth/queries/shopLogin';

export const useGemPagesAuthentication = (
  connectedType: ComputedRef<ConnectedType>,
  editorType: ComputedRef<string>,
) => {
  const route = useRoute();
  const shopStore = useShopStore();
  const { handleError } = useNotification();

  const shopIDQuery = computed(() => route.query?.shop_id?.toString());
  const shopTokenQuery = computed(() => route.query?.token?.toString());
  const shopifyDomain = computed(() => route.query?.shop?.toString());

  const isEnableShopifyAuth = computed(
    () =>
      (connectedType.value === 'editor' && editorType.value === 'gempagesv7') ||
      (connectedType.value === 'gempagesv7' && !(shopIDQuery.value && shopTokenQuery.value && shopifyDomain.value)),
  );

  useShopLoginQuery(
    {
      connectedType: 'gempagesv7',
    },
    {
      enabled: isEnableShopifyAuth,
      onError: handleError,
      onSuccess: (v) => {
        handleSuccess(v);
      },
    },
  );

  const shopID = computed(() => shopStore.getShopId);

  const handleSuccess = (v: ShopLoginResponse) => {
    updateShopInfoInStore(v);

    if (v.shopID === '0' || !v.shopID) {
      backToDashboard({
        delayRedirect: 8000,
      });
    }
  };

  const updateShopInfoInStore = (v: ShopLoginResponse) => {
    if (!shopStore.getIsAdminLogin) {
      shopStore.setShopId(v?.shopID);
      shopStore.setToken(v?.token);
      shopStore.setShopDomain(v?.shopifyDomain);
    }
    shopStore.setUserHash(v?.userHash);
    shopStore.setFirstName(v?.firstName);
    const isConnected = v?.shopID && v?.shopID !== '0' && v?.status == 'CONNECTED';
    shopStore.setStatus(!!isConnected);
    shopStore.setShopType(v.isGemPagesV7);
    shopStore.setShopPlan(v.plan);
    shopStore.setShopifyPlan(v.shopifyPlan);
    emitUpdateShopPlan(v?.plan);
  };

  useShopQuery({
    shopID,
  });

  onMounted(() => {
    if (shopIDQuery.value && shopifyDomain.value && shopTokenQuery.value) {
      if (!shopStore.getIsAdminLogin) {
        shopStore.setShopId(shopIDQuery.value);
        shopStore.setToken(shopTokenQuery.value);
        shopStore.setShopDomain(shopifyDomain.value);
      }
      shopStore.setStatus(true);
    }
  });
};
